import cx from 'classnames';
import { Icon } from '@components/icon/Icon';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import typography from '~styles/typography.scss';
import styles from './ReservationNote.scss';
import { ReservationNoteModal } from './ReservationNoteModal';

interface ReservationNoteProps {
  reservationId: string;
  note: string | null;
}

export const ReservationNote = ({
  reservationId,
  note,
}: ReservationNoteProps) => {
  const {
    isOpen: isRestaurantNoteModalOpen,
    open: openRestaurantNoteModal,
    close: closeRestaurantNoteModal,
  } = useIsOpen();

  return (
    <section>
      <button
        className={cx(styles.button, typography.h4)}
        type="button"
        onClick={openRestaurantNoteModal}
      >
        Reservation Note
        <Icon name="chevron" />
      </button>
      <ReservationNoteModal
        reservationId={reservationId}
        note={note}
        isOpen={isRestaurantNoteModalOpen}
        closeModal={closeRestaurantNoteModal}
      />
    </section>
  );
};
