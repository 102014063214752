// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReservationCardActions__container___hi4n1{border-top:1px solid var(--hairline);display:flex;flex-direction:column;margin:0;margin-inline:1rem;padding-top:1rem;gap:.5rem}@media only screen and (min-width: 768px){.ReservationCardActions__container___hi4n1{border:none;flex-direction:column-reverse;justify-content:space-between;margin:.5rem 0;min-width:12.25rem;padding:1rem;width:12.25rem}}@media only screen and (min-width: 1024px){.ReservationCardActions__container___hi4n1{margin:.5rem .5rem .5rem 0}}.ReservationCardActions__container___hi4n1 button{color:#fff;height:2rem;width:100%}@media only screen and (min-width: 768px){.ReservationCardActions__container___hi4n1 button{height:2.5rem;padding-inline:.25rem}.ReservationCardActions__container___hi4n1 button.ReservationCardActions__listPriceButton___AUTVz{color:var(--soldBlue);padding-inline:0;text-align:right}}.ReservationCardActions__container___hi4n1:empty{display:none}@media only screen and (min-width: 768px){.ReservationCardActions__container___hi4n1:empty{display:block}}", "",{"version":3,"sources":["webpack://./src/webReservations/reservations/ReservationCardActions.scss","webpack://./src/shared/styles/breakpoints.scss"],"names":[],"mappings":"AAEA,2CACE,oCAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CACA,kBAAA,CACA,gBAAA,CACA,SAAA,CCuBA,0CD9BF,2CAUI,WAAA,CACA,6BAAA,CACA,6BAAA,CACA,cAAA,CACA,kBAAA,CACA,YAAA,CACA,cAAA,CAAA,CCcF,2CD9BF,2CAoBI,0BAAA,CAAA,CAGF,kDACE,UAAA,CACA,WAAA,CACA,UAAA,CCIF,0CDPA,kDAMI,aAAA,CACA,qBAAA,CAEA,kGACE,qBAAA,CACA,gBAAA,CACA,gBAAA,CAAA,CAKN,iDACE,YAAA,CCXF,0CDUA,iDAII,aAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ReservationCardActions__container___hi4n1",
	"listPriceButton": "ReservationCardActions__listPriceButton___AUTVz"
};
export default ___CSS_LOADER_EXPORT___;
