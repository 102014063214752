// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReservationsLayout__container___fsW7g{align-items:center;background-color:var(--primary400);display:flex;flex-direction:column;margin:0 16px 16px;padding:0}@media only screen and (min-width: 390px){.ReservationsLayout__container___fsW7g{margin:0 32px 16px;padding:16px 0}}@media only screen and (min-width: 768px){.ReservationsLayout__container___fsW7g{align-items:start;margin:0 auto;max-width:var(--pageContentMaxWidth);padding:0 40px;width:100%}}@media only screen and (min-width: 1280px){.ReservationsLayout__container___fsW7g{margin:32px auto 16px}}.ReservationsLayout__title___yAnfP{margin-top:32px}@media only screen and (min-width: 768px){.ReservationsLayout__title___yAnfP{margin-top:32px;text-align:left}}.ReservationsLayout__tabs___iwOJw{border-bottom:1px solid var(--hairline);display:flex;flex-direction:row;column-gap:16px;margin-top:0;width:100%}.ReservationsLayout__tab___zE3o1{color:var(--white);margin-bottom:-1px;padding:16px;text-align:center;width:100%}@media only screen and (min-width: 768px){.ReservationsLayout__tab___zE3o1{text-align:unset;width:unset}}.ReservationsLayout__tab___zE3o1.ReservationsLayout__isActive___gYrjC{border-bottom:1px solid var(--accent300)}", "",{"version":3,"sources":["webpack://./src/webReservations/reservations/ReservationsLayout.scss","webpack://./src/shared/styles/breakpoints.scss"],"names":[],"mappings":"AAEA,uCACE,kBAAA,CACA,kCAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,SAAA,CCwBA,0CD9BF,uCASI,kBAAA,CACA,cAAA,CAAA,CCoBF,0CD9BF,uCAcI,iBAAA,CACA,aAAA,CACA,oCAAA,CACA,cAAA,CACA,UAAA,CAAA,CCYF,2CD9BF,uCAsBI,qBAAA,CAAA,CAIJ,mCACE,eAAA,CCGA,0CDJF,mCAII,eAAA,CACA,eAAA,CAAA,CAIJ,kCACE,uCAAA,CACA,YAAA,CACA,kBAAA,CACA,eAAA,CACA,YAAA,CACA,UAAA,CAGF,iCACE,kBAAA,CACA,kBAAA,CACA,YAAA,CACA,iBAAA,CACA,UAAA,CCnBA,0CDcF,iCAQI,gBAAA,CACA,WAAA,CAAA,CAGF,sEACE,wCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ReservationsLayout__container___fsW7g",
	"title": "ReservationsLayout__title___yAnfP",
	"tabs": "ReservationsLayout__tabs___iwOJw",
	"tab": "ReservationsLayout__tab___zE3o1",
	"isActive": "ReservationsLayout__isActive___gYrjC"
};
export default ___CSS_LOADER_EXPORT___;
