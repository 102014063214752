import { api } from 'webReservations/api';

export interface Restaurant {
  id: string;
  name: string;
  description: string;
  address: string;
  phone: string;
  website: string;
}

export enum ApiStatus {
  Active = 'active',
  Canceled = 'canceled',
  Finished = 'finished',
  NoShow = 'noShow',
  Resold = 'resold',
  AutoResolved = 'autoResolved',
}

export interface Reservation {
  buyNowPrice: number | null;
  date: string;
  guestCount: number;
  id: string;
  restaurant: Restaurant;
  status?: ApiStatus;
  listing: {
    publicName: string;
  };
  time: string;
}

export const clearListPrice = async (reservationId: string): Promise<void> =>
  api.post(`/reservations/${reservationId}/clear-buy-now`);

export const getConfirmedReservations = async (): Promise<Reservation[]> =>
  api.get(`/reservations/confirmed`);

export const getInactiveReservations = async (): Promise<Reservation[]> =>
  api.get('/reservations/inactive');

export const updateListPrice = async (
  reservationId: string,
  price: number,
): Promise<void> =>
  api.post(`/reservations/${reservationId}/set-buy-now`, { price });

export const updateReservationNote = async (
  reservationId: string,
  note: string | null,
) => api.post(`/reservations/${reservationId}/update-note`, { note });
