import { api } from 'webReservations/api';

export enum OfferStatus {
  Active = 'active',
  Accepted = 'accepted',
  Canceled = 'canceled',
}

export interface Offer {
  date: string;
  expiredAt: string;
  guestCount: number;
  id: string;
  listing: {
    id: string;
    publicName: string;
  };
  price: number;
  restaurant: {
    id: string;
    name: string;
  };
  status: OfferStatus;
  transaction?: {
    paymentMethod: string;
  };
  time: string;
}

export interface OfferDetails {
  date: string;
  expiredAt: string;
  guestCount: number;
  id: string;
  listing: {
    id: string;
    publicName: string;
  };
  price: number;
  restaurant: {
    address: string;
    id: string;
    name: string;
    phone: string;
    postalCode: string;
    website: string;
  };
  status: OfferStatus;
  time: string;
  transaction: { paymentMethod: string };
}

export const acceptOffer = async (
  offerId: string,
  reservationId: string,
): Promise<void> =>
  api.post(`/offers/${offerId}/accept`, {
    reservationId,
  });

export const cancelOffer = async (offerId: string): Promise<void> =>
  api.post(`/offers/${offerId}/cancel`);

export const getOffer = async (offerId: string): Promise<OfferDetails> =>
  api.get(`/offers/${offerId}`);

export const getOffersOut = async (): Promise<Offer[]> => api.get('/offers');
