// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReservationTabs__container___hwOPk{display:flex;flex-direction:column;gap:1rem;margin-top:0;max-width:100%;padding-top:2.25rem}@media only screen and (min-width: 768px){.ReservationTabs__container___hwOPk{margin-bottom:2.5rem}}", "",{"version":3,"sources":["webpack://./src/webReservations/reservations/ReservationTabs.scss","webpack://./src/shared/styles/breakpoints.scss"],"names":[],"mappings":"AAEA,oCACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,YAAA,CACA,cAAA,CACA,mBAAA,CCwBA,0CD9BF,oCASI,oBAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ReservationTabs__container___hwOPk"
};
export default ___CSS_LOADER_EXPORT___;
