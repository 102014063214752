import { useForm } from 'react-hook-form';
import { Button, ButtonVariants } from '@components/button/Button';
import { ControlledFormTextareaAutosize } from '@components/formInputs/ControlledFormTextareaAutosize';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { reportAppError } from '@shared/reportAppError';
import { updateReservationNote } from '../reservations/apiHelpers';

interface RestaurantNoteFormData {
  note?: string;
}

export interface PricePointModalProps {
  reservationId: string;
  note: string | null;
  isOpen: boolean;
  closeModal: () => void;
}

export const ReservationNoteModal = ({
  reservationId,
  note,
  isOpen,
  closeModal,
}: PricePointModalProps) => {
  const { control, handleSubmit } = useForm<RestaurantNoteFormData>({
    defaultValues: {
      note: note || '',
    },
  });

  const handleOnSubmit = handleSubmit(async (data: RestaurantNoteFormData) => {
    try {
      await updateReservationNote(reservationId, data.note || null);
      successToast({ message: 'Successfully updated your reservation note.' });
      closeModal();
    } catch (error) {
      if (error instanceof ApiError) {
        errorToast({ message: error.message });
      } else {
        errorToast({
          message: 'Failed to update your reservation note. Please try again.',
        });
      }
      reportAppError(error);
    }
  });

  return (
    <Modal isOpen={isOpen} onClose={closeModal} title="Notes to Restaurant">
      <form onSubmit={handleOnSubmit}>
        <ControlledFormTextareaAutosize
          control={control}
          label="Note"
          name="note"
          rules={{
            maxLength: {
              message: 'Your note must be 600 characters or less',
              value: 600,
            },
          }}
        />
        <ModalActions>
          <Button
            type="button"
            label="Cancel"
            onClick={closeModal}
            variant={ButtonVariants.Tertiary}
          />
          <Button type="submit" label="Save" variant={ButtonVariants.Primary} />
        </ModalActions>
      </form>
    </Modal>
  );
};
