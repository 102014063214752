const SECONDS_IN_DAY = 86400;
const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;
const NO_TIME_REMAINING = 0;

export const formatTimeRemaining = (timeInSeconds = 0): string => {
  if (timeInSeconds <= NO_TIME_REMAINING) return 'Expired';

  const timeBuckets: Record<string, number> = {
    d: Math.floor(timeInSeconds / SECONDS_IN_DAY),
    h: Math.floor(timeInSeconds / SECONDS_IN_HOUR) % HOURS_IN_DAY,
    m: Math.floor(timeInSeconds / SECONDS_IN_MINUTE) % MINUTES_IN_HOUR,
    s: timeInSeconds % SECONDS_IN_MINUTE,
  };

  const [unit, timeValue] =
    Object.entries(timeBuckets).find(
      ([_unit, value]) => value !== NO_TIME_REMAINING,
    ) || [];

  return `${timeValue}${unit}`;
};
