// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OfferCheckoutConfirmationPage__main___NdQ37{color:var(--white);width:100vw;margin:0 auto 40px;padding:16px 12px 0}@media only screen and (min-width: 768px){.OfferCheckoutConfirmationPage__main___NdQ37{width:600px}}.OfferCheckoutConfirmationPage__header___QkVJK h1{margin:24px 0 16px;text-align:center}.OfferCheckoutConfirmationPage__header___QkVJK h3{text-align:center;opacity:.7}.OfferCheckoutConfirmationPage__reservation___Ev1dk{background-color:var(--darkBlue);border-radius:8px;flex-basis:50%;padding:24px;margin:40px auto;max-width:368px}.OfferCheckoutConfirmationPage__restaurantName___VPWcI{margin:0 0 12px}.OfferCheckoutConfirmationPage__reservationAttributesContainer___EsdKa{margin-bottom:24px}.OfferCheckoutConfirmationPage__actions___HSIPW{margin:0 auto;margin-top:16px;max-width:368px}", "",{"version":3,"sources":["webpack://./src/webReservations/checkout/OfferCheckoutConfirmationPage.scss","webpack://./src/shared/styles/breakpoints.scss"],"names":[],"mappings":"AAEA,6CACE,kBAAA,CACA,WAAA,CACA,kBAAA,CACA,mBAAA,CC0BA,0CD9BF,6CAOI,WAAA,CAAA,CAKF,kDACE,kBAAA,CACA,iBAAA,CAGF,kDACE,iBAAA,CACA,UAAA,CAIJ,oDACE,gCAAA,CACA,iBAAA,CACA,cAAA,CACA,YAAA,CACA,gBAAA,CACA,eAAA,CAGF,uDACE,eAAA,CAGF,uEACE,kBAAA,CAGF,gDACE,aAAA,CACA,eAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "OfferCheckoutConfirmationPage__main___NdQ37",
	"header": "OfferCheckoutConfirmationPage__header___QkVJK",
	"reservation": "OfferCheckoutConfirmationPage__reservation___Ev1dk",
	"restaurantName": "OfferCheckoutConfirmationPage__restaurantName___VPWcI",
	"reservationAttributesContainer": "OfferCheckoutConfirmationPage__reservationAttributesContainer___EsdKa",
	"actions": "OfferCheckoutConfirmationPage__actions___HSIPW"
};
export default ___CSS_LOADER_EXPORT___;
