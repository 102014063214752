import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Icon } from '@components/icon/Icon';
import typography from '~styles/typography.scss';
import { ImageLoader } from '../components/ImageLoader';
import styles from './RestaurantCard.scss';

export interface RestaurantCardProps {
  address: string;
  id: string;
  locationSlug: string;
  name: string;
  nameSlug: string;
}

export const RestaurantCard = ({
  address,
  id,
  locationSlug,
  name,
  nameSlug,
}: RestaurantCardProps) => (
  <Link className={styles.card} to={`${locationSlug}/${nameSlug}`}>
    <ImageLoader
      className={styles.image}
      src={`${process.env.RESTAURANT_IMAGE_BASE_URL}/${id}/main.jpg`}
    />
    <div className={styles.cardLabel}>
      <div className={cx(typography.d3, styles.name)}>{name}</div>
      <div className={styles.address}>
        <Icon className={styles.icon} name="locationArrow" />
        {address}
      </div>
    </div>
  </Link>
);
