import cx from 'classnames';
import type { FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { Error } from '@components/error/Error';
import { ApiError } from '@shared/api/errors';
import { reportAppError } from '@shared/reportAppError';
import { isCancellationPolicyApplicable } from '@shared/reservations/reservationUtil';
import { CONFLICT } from '@shared/statusCodes';
import { CANCELLATION_POLICY_HAS_CHANGED_ERROR_MESSAGE } from 'webReservations/errors/messages';
import { getRestaurantDetailsById } from 'webReservations/restaurant/apiHelpers';
import typography from '~styles/typography.scss';
import { useOfferCheckoutContext } from '../context/OfferCheckoutContext';
import { useRestaurantContext } from '../context/RestaurantContext';
import { OFFER_CHECKOUT_CONFIRMATION_TERMINAL_PATH } from '../paths';
import { createOffer } from './apiHelpers';
import styles from './CheckoutForm.scss';
import { sendGa4SendOfferEvent } from './gaHelpers';
import { PaymentMethods } from './PaymentMethods';
import { usePaymentMethods } from './usePaymentMethods';

interface OfferCheckoutForm {
  disabled: boolean;
  offerAmount: number;
}

export const OfferCheckoutForm = ({
  disabled,
  offerAmount,
}: OfferCheckoutForm) => {
  const navigate = useNavigate();
  const { restaurantDetails, setRestaurantDetails } = useRestaurantContext();
  const {
    offerCheckout: { date, guestCount, listingId, publicName, time },
  } = useOfferCheckoutContext();

  const {
    processPaymentMethod,
    selectedPaymentMethodId,
    setupFutureCardUsage,
    handleOnError,
    isLoading,
    paymentMethods,
    handleOnChangePaymentMethod,
    handleOnChangeName,
    handleOnChangeCardElement,
    handleOnChangeSaveCard,
    handleOnDeletePaymentMethod,
    checkoutResponseError,
    isPayButtonDisabled,
  } = usePaymentMethods();

  const handleOnClickSendOffer = async (event: FormEvent) => {
    event.preventDefault();

    const { cancellationPolicy } = restaurantDetails;
    const hasApplicableCancellationPolicy =
      !!cancellationPolicy &&
      isCancellationPolicyApplicable(offerAmount, cancellationPolicy.threshold);

    const newPaymentMethod = await processPaymentMethod(event);
    if (!selectedPaymentMethodId && !newPaymentMethod) return;

    try {
      const createOfferResponse = await createOffer({
        date,
        expectedCancellationPolicyId: hasApplicableCancellationPolicy
          ? cancellationPolicy.id
          : null,
        guestCount,
        listingId,
        offerAmount,
        paymentMethodId:
          selectedPaymentMethodId || newPaymentMethod?.paymentMethod.id || '',
        setupFutureCardUsage,
        time,
      });
      navigate(OFFER_CHECKOUT_CONFIRMATION_TERMINAL_PATH, {
        replace: true,
        state: {
          date,
          fee: createOfferResponse.fee,
          guestCount,
          offerAmount: createOfferResponse.price,
          publicName,
          tax: createOfferResponse.tax,
          time,
          total: createOfferResponse.total,
        },
      });

      sendGa4SendOfferEvent({
        date,
        offerAmount: createOfferResponse.price,
        restaurantName: restaurantDetails.name,
        publicName,
        time,
      });
    } catch (e) {
      if (e instanceof ApiError) {
        if (e.statusCode === CONFLICT) {
          try {
            const restaurantDetailsResponse = await getRestaurantDetailsById(
              restaurantDetails.id,
            );
            setRestaurantDetails(restaurantDetailsResponse);
            handleOnError(CANCELLATION_POLICY_HAS_CHANGED_ERROR_MESSAGE);
            return;
          } catch (restaurantDetailsError) {
            handleOnError('something went wrong');
            reportAppError(restaurantDetailsError);
            return;
          }
        }
        handleOnError('something went wrong');
        reportAppError(e);
      }
      handleOnError('something went wrong');
      reportAppError(e);
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <form id="payment-form" onSubmit={handleOnClickSendOffer}>
      <PaymentMethods
        paymentMethods={paymentMethods}
        selectedPaymentMethodId={selectedPaymentMethodId}
        setupFutureCardUsage={setupFutureCardUsage}
        handleOnChangePaymentMethod={handleOnChangePaymentMethod}
        handleOnChangeName={handleOnChangeName}
        handleOnChangeCardElement={handleOnChangeCardElement}
        handleOnChangeSaveCard={handleOnChangeSaveCard}
        handleOnDeletePaymentMethod={handleOnDeletePaymentMethod}
      />
      <p className={cx(typography.t2, styles.offerNotice)}>
        Your credit card will not be charged unless the offer is accepted
      </p>
      <Error message={checkoutResponseError} />
      <Button
        className={styles.bookAndPay}
        isDisabled={isPayButtonDisabled || disabled}
        label="Send Offer"
        type="submit"
        variant={ButtonVariants.Primary}
      />
      <Button
        className={styles.cancel}
        label="Cancel"
        onClick={() => navigate(-1)}
        variant={ButtonVariants.Tertiary}
      />
    </form>
  );
};
