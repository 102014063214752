import { api } from 'webReservations/api';

interface PayoutLink {
  linkToken: { linkToken: string };
}

export const getPayoutLinkToken = async (): Promise<PayoutLink> =>
  api.get('/payouts/link-token');

export const createLinkedAccount = async (
  publicToken: string,
  accountId: string,
): Promise<void> =>
  api.post('/payouts/linked-account', {
    publicToken,
    accountId,
  });
