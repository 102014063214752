import { useEffect, useState } from 'react';
import { useDocumentTitle } from '@shared/hooks/setDocumentTitle';
import { reportAppError } from '@shared/reportAppError';
import { EmptyState } from '../components/EmptyState';
import type { Reservation } from './apiHelpers';
import { getConfirmedReservations } from './apiHelpers';
import { ReservationCard } from './ReservationCard';
import { ReservationCardActions } from './ReservationCardActions';
import styles from './ReservationTabs.scss';
import { ReservationStatusType } from './statusHelpers';

const NO_RESERVATIONS = 0;

export const ConfirmedPage = () => {
  const [confirmedReservations, setConfirmedReservations] = useState<
    Reservation[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  useDocumentTitle('My Confirmed Reservations | Peak Reservations');

  const fetchConfirmedReservations = async () => {
    try {
      const response = await getConfirmedReservations();
      setConfirmedReservations(response);
    } catch (error) {
      reportAppError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchConfirmedReservations();
  }, []);

  if (confirmedReservations.length === NO_RESERVATIONS && !isLoading) {
    return (
      <EmptyState
        title="No Reservations"
        subtitle="World-class experiences await. Book your next reservation today."
      />
    );
  }

  return (
    <ul className={styles.container}>
      {confirmedReservations.map((reservation) => (
        <ReservationCard
          date={reservation.date}
          guestCount={reservation.guestCount}
          restaurant={reservation.restaurant}
          listingPublicName={reservation.listing.publicName}
          time={reservation.time}
          key={JSON.stringify(reservation)}
          linkTo={`/reservations/${reservation.id}`}
          status={ReservationStatusType.Confirmed}
        >
          <ReservationCardActions
            buyNowPrice={reservation.buyNowPrice}
            fetchConfirmedReservations={fetchConfirmedReservations}
            reservation={reservation}
          />
        </ReservationCard>
      ))}
    </ul>
  );
};
