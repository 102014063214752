import { api } from 'webReservations/api';

export interface NotificationSetting {
  enabled: boolean;
  name: string;
}

interface SMSNotificationSettings {
  settings: NotificationSetting[];
}

export interface UserAccountData {
  balance: number;
  linkedAccountId: string;
}

export const getSMSNotificationSettings =
  async (): Promise<SMSNotificationSettings> =>
    api.get(`/notification-settings`);

export const updateSMSNotificationSettings = async (
  settings: SMSNotificationSettings,
): Promise<void> => api.put(`/notification-settings`, settings);

export const getUserAccountData = async (): Promise<UserAccountData> =>
  api.get(`/user/account`);

export const transferBalance = async (): Promise<void> =>
  api.post('/payouts/transfer');

export const deleteBankAccount = async (): Promise<void> =>
  api.delete('/payouts/linked-account');
