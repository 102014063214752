import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useDefinedContext } from '@shared/hooks/useDefinedContext';
import { useError } from '@shared/hooks/useError';
import { reportAppError } from '@shared/reportAppError';
import * as apiHelpers from '../auth/apiHelpers';
import type { AuthenticatedUser, CheckAuthResponse } from '../auth/apiHelpers';
import { LANDING_PATH } from '../paths';
import { PLAID_LINK_TOKEN_KEY } from './PlaidContext';

export interface UserContextState {
  user?: AuthenticatedUser;
  isAuthenticated: boolean;
  isLoading: boolean;
  setUser: Dispatch<SetStateAction<AuthenticatedUser | undefined>>;
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
  signOut: () => Promise<void>;
  clearUserState: () => void;
}

const UserContext = createContext<UserContextState | null>(null);
UserContext.displayName = 'User';

export const useUserContext = () => useDefinedContext(UserContext);

interface UserContextProviderProps {
  children: ReactNode;
}

export const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<AuthenticatedUser>();
  const setError = useError();

  const signOut = async (): Promise<void> => {
    try {
      await apiHelpers.signOut();
      localStorage.removeItem(PLAID_LINK_TOKEN_KEY);
      window.location.replace(LANDING_PATH);
    } catch (e) {
      setError(e);
      reportAppError(e);
    }
  };

  const clearUserState = (): void => {
    setIsAuthenticated(false);
    setUser(undefined);
  };

  const fetchAuthState = async (): Promise<void> => {
    setIsLoading(true);

    try {
      const response: CheckAuthResponse = await apiHelpers.checkAuth();
      if (response.isAuthenticated) {
        setIsAuthenticated(true);
        setUser(response.user);
      } else {
        clearUserState();
      }
    } catch (e) {
      setError(e);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    void fetchAuthState();
  }, []);

  const value = useMemo<UserContextState>(
    () => ({
      user,
      setUser,
      isAuthenticated,
      isLoading,
      setIsAuthenticated,
      clearUserState,
      signOut,
    }),
    [user, isAuthenticated, isLoading],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
