import cx from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { NavLink, Outlet } from 'react-router-dom';
import { useDocumentTitle } from '@shared/hooks/setDocumentTitle';
import { MOBILE } from '@shared/styles/breakpoints';
import typography from '~styles/typography.scss';
import { useUserContext } from '../context/UserContext';
import { Unauthenticated } from '../components/Unauthenticated';
import { Layout } from '../layout/Layout';
import {
  RESERVATIONS_CONFIRMED_PATH,
  RESERVATIONS_INACTIVE_PATH,
} from '../paths';
import styles from './ReservationsLayout.scss';

export const ReservationsLayout = () => {
  const { isAuthenticated } = useUserContext();
  const isTabletOrMobile = useMediaQuery({ maxWidth: MOBILE });

  useDocumentTitle('My Reservations | Peak Reservations');

  const tabClassNames = ({ isActive }: { isActive: boolean }) =>
    cx({
      [styles.tab]: true,
      [styles.isActive]: isActive,
    });

  const titleClassNames = cx({
    [styles.title]: true,
    [typography.h5]: isTabletOrMobile,
    [typography.d3]: !isTabletOrMobile,
  });

  return (
    <Layout>
      {isAuthenticated ? (
        <main className={styles.container}>
          <h1 className={titleClassNames}>Reservations</h1>
          <div className={styles.tabs}>
            <NavLink className={tabClassNames} to={RESERVATIONS_CONFIRMED_PATH}>
              Confirmed
            </NavLink>
            <NavLink className={tabClassNames} to={RESERVATIONS_INACTIVE_PATH}>
              Inactive
            </NavLink>
          </div>
          <Outlet />
        </main>
      ) : (
        <Unauthenticated
          subtitle="Book a table to see it here."
          title="See all your reservations here in one place"
        />
      )}
    </Layout>
  );
};
