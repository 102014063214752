import { api } from '../api';

export interface CancellationPolicy {
  applicableAt: string;
  fee: number;
}

export interface ReservationWithDetails {
  buyNowPrice: number | null;
  date: string;
  guestCount: number;
  id: string;
  isCancelableByGuest: boolean;
  note: string | null;
  restaurant: {
    address: string;
    description: string;
    id: string;
    name: string;
    phone: string;
    website: string;
  };
  soldForPrice: number | null;
  status: 'CANCELED' | 'SOLD' | 'COMPLETED' | 'CONFIRMED';
  listing: {
    id: string;
    publicName: string;
  };
  time: string;
  transaction: {
    fee: number;
    paymentMethod: string;
    price: number;
    purchaseDate: string; // ISO 8601 date format
    refundAmount?: number;
    tax: number;
    receiptNumber: string;
    cancellationPolicy: CancellationPolicy | null;
  };
}

export type TopOffer =
  | {
      expiredAt: string; // ISO 8601 date format
      id: string;
      price: number;
    }
  | Record<string, never>;

export const getReservation = async (
  reservationId: string,
): Promise<ReservationWithDetails> => api.get(`/reservations/${reservationId}`);

export const getTopOffer = async (
  reservationId: string,
  signal?: AbortSignal,
): Promise<TopOffer> =>
  api.get(`/reservations/${reservationId}/top-offer`, {
    signal,
  });

export const cancelReservation = async (reservationId: string): Promise<void> =>
  api.post(`/reservations/${reservationId}/cancel`);
