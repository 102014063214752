// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer__footer___Znb_e{align-items:flex-start;display:flex;flex-direction:column;justify-content:space-between;padding:2rem;row-gap:1rem;width:100%}@media only screen and (min-width: 1024px){.Footer__footer___Znb_e{align-items:baseline;background-color:var(--darkGrey400);flex-direction:row;height:var(--footerHeight);padding:1rem;z-index:var(--z_desktopFooter)}}.Footer__footer___Znb_e nav{align-items:flex-start;display:flex;flex-direction:column;row-gap:1rem}@media only screen and (min-width: 1024px){.Footer__footer___Znb_e nav{align-items:baseline;column-gap:1.5rem;flex-direction:row}}.Footer__footer___Znb_e nav .Footer__link___JLqLp{color:var(--white);white-space:nowrap}.Footer__footer___Znb_e nav .Footer__supportLink___Dkgb5{border:0;font-size:1rem;font-weight:400;height:auto;line-height:1.5;padding:0;width:min-content}", "",{"version":3,"sources":["webpack://./src/webReservations/layout/Footer.scss","webpack://./src/shared/styles/breakpoints.scss"],"names":[],"mappings":"AAEA,wBACE,sBAAA,CACA,YAAA,CACA,qBAAA,CACA,6BAAA,CACA,YAAA,CACA,YAAA,CACA,UAAA,CCuBA,2CD9BF,wBAUI,oBAAA,CACA,mCAAA,CACA,kBAAA,CACA,0BAAA,CACA,YAAA,CACA,8BAAA,CAAA,CAGF,4BACE,sBAAA,CACA,YAAA,CACA,qBAAA,CACA,YAAA,CCQF,2CDZA,4BAOI,oBAAA,CACA,iBAAA,CACA,kBAAA,CAAA,CAGF,kDACE,kBAAA,CACA,kBAAA,CAGF,yDACE,QAAA,CACA,cAAA,CACA,eAAA,CACA,WAAA,CACA,eAAA,CACA,SAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "Footer__footer___Znb_e",
	"link": "Footer__link___JLqLp",
	"supportLink": "Footer__supportLink___Dkgb5"
};
export default ___CSS_LOADER_EXPORT___;
