import type { LoaderFunctionArgs } from 'react-router-dom';
import {
  getRestaurantDetailsBySlug,
  type RestaurantDetails,
} from '../restaurant/apiHelpers';

export const restaurantLoader = async ({
  params: { locationSlug = '', nameSlug = '' },
}: LoaderFunctionArgs<{
  params: { locationSlug: string; nameSlug: string };
}>): Promise<RestaurantDetails> =>
  getRestaurantDetailsBySlug(locationSlug, nameSlug);
