import { useEffect, useState } from 'react';
import { useDocumentTitle } from '@shared/hooks/setDocumentTitle';
import { reportAppError } from '@shared/reportAppError';
import { EmptyState } from '../components/EmptyState';
import { getInactiveReservations } from './apiHelpers';
import type { Reservation } from './apiHelpers';
import { ReservationCard } from './ReservationCard';
import styles from './ReservationTabs.scss';
import { INACTIVE_RESERVATION_STATUS_MAP } from './statusHelpers';

const NO_RESERVATIONS = 0;

export const InactivePage = () => {
  const [inactiveReservations, setInactiveReservations] = useState<
    Reservation[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  useDocumentTitle('My Inactive Reservations | Peak Reservations');

  useEffect(() => {
    const fetchInactiveReservations = async () => {
      try {
        const response = await getInactiveReservations();
        setInactiveReservations(response);
      } catch (error) {
        reportAppError(error);
      }
      setIsLoading(false);
    };
    fetchInactiveReservations();
  }, []);

  if (inactiveReservations.length === NO_RESERVATIONS && !isLoading) {
    return (
      <EmptyState
        title="No Reservations"
        subtitle="World-class experiences await. Book your next reservation today."
      />
    );
  }

  return (
    <ul className={styles.container}>
      {inactiveReservations.map((reservation) => (
        <ReservationCard
          date={reservation.date}
          guestCount={reservation.guestCount}
          restaurant={reservation.restaurant}
          listingPublicName={reservation.listing.publicName}
          time={reservation.time}
          key={JSON.stringify(reservation)}
          linkTo={`/reservations/${reservation.id}`}
          status={
            reservation.status
              ? INACTIVE_RESERVATION_STATUS_MAP[reservation.status]
              : undefined
          }
        />
      ))}
    </ul>
  );
};
