import { isEmpty } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useDocumentTitle } from '@shared/hooks/setDocumentTitle';
import { reportAppError } from '@shared/reportAppError';
import { EmptyState } from '../components/EmptyState';
import { getTopOffer, type TopOffer } from '../reservationDetails/apiHelpers';
import {
  getConfirmedReservations,
  type Reservation,
} from '../reservations/apiHelpers';
import { sendGa4OffersInViewEvent } from './gaHelpers';
import { OfferInCard } from './OfferInCard';
import styles from './OfferTabs.scss';

interface ReservationWithTopOffer {
  reservation: Reservation;
  topOffer: TopOffer;
}

export const OffersInPage = () => {
  const [offersIn, setOffersIn] = useState<ReservationWithTopOffer[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  useDocumentTitle('My Incoming Offers | Peak Reservations');

  const fetchData = () => {
    void (async () => {
      try {
        const confirmedReservationsResponse = await getConfirmedReservations();

        const topOffers: TopOffer[] = await Promise.all(
          confirmedReservationsResponse.map(({ id }) => getTopOffer(id)),
        );

        const reservationsWithOffers: ReservationWithTopOffer[] =
          confirmedReservationsResponse.reduce(
            (
              filteredReservations: ReservationWithTopOffer[],
              reservation,
              index,
            ) => {
              const topOffer = topOffers[index];
              if (!isEmpty(topOffer)) {
                return [
                  ...filteredReservations,
                  { reservation, topOffer } as ReservationWithTopOffer,
                ];
              }

              return filteredReservations;
            },
            [] as ReservationWithTopOffer[],
          );
        setOffersIn(reservationsWithOffers);
      } catch (error) {
        reportAppError(error);
      }
    })();
    setIsLoading(false);
  };

  useEffect(() => {
    sendGa4OffersInViewEvent();
    fetchData();
  }, []);

  if (!offersIn.length && !isLoading) {
    return (
      <EmptyState
        title="Avoid cancellation fees if you can’t make a reservation"
        subtitle="Accept offers for reservations that you no longer want."
      />
    );
  }

  return (
    <ul className={styles.container}>
      {offersIn.map((offerIn) => (
        <OfferInCard key={JSON.stringify(offerIn)} {...offerIn} />
      ))}
    </ul>
  );
};
