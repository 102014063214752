import { useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { ControlledFormPhoneInput } from '@components/formInputs/ControlledFormPhoneInput';
import { ApiError } from '@shared/api/errors';
import { reportAppError } from '@shared/reportAppError';
import { TERMS_OF_SERVICE_PATH } from 'webReservations/paths';
import typography from '~styles/typography.scss';
import {
  AuthModalTypes,
  useAuthModalContext,
} from '../context/AuthModalContext';
import { sendLoginVerificationCode } from './apiHelpers';
import { AuthModal } from './AuthModal';
import styles from './SignInModal.scss';

const noOp = () => undefined;

interface SignInModalProps {
  onAuthSuccess?: () => void;
}

interface SignInFormData {
  phone: string;
}

export const SignInModal = ({ onAuthSuccess = noOp }: SignInModalProps) => {
  const signInForm = useForm<SignInFormData>();
  const { handleSubmit } = signInForm;
  const { openModal, nextModal, closeModal } = useAuthModalContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [sendOTPError, setSendOTPError] = useState<string>('');

  const submitPhoneNumber: SubmitHandler<SignInFormData> = async (data) => {
    const { phone } = data;

    setLoading(true);
    try {
      await sendLoginVerificationCode(phone);
      nextModal(AuthModalTypes.SIGN_IN_OTP, {
        onAuthSuccess,
        phone,
      });
    } catch (error) {
      if (error instanceof ApiError) {
        setSendOTPError(error.message);
      } else {
        setSendOTPError('An error occurred. Please try again.');
      }
      reportAppError(error);
    }

    setLoading(false);
  };

  return (
    <AuthModal
      label="Sign In"
      submitHandler={handleSubmit(submitPhoneNumber)}
      title="Please Log In to Continue"
    >
      <div className={styles.container}>
        <ControlledFormPhoneInput
          autoFocus
          control={signInForm.control}
          customError={sendOTPError}
          label="Phone"
          name="phone"
          rules={{ required: true }}
        />
        <p className={typography.c3}>
          By continuing to use Peak services, you are agreeing to these updated{' '}
          <Link
            className={styles.legalLink}
            onClick={closeModal}
            to={TERMS_OF_SERVICE_PATH}
          >
            Terms of Service
          </Link>
          .
        </p>
        <Button
          isDisabled={loading || !!sendOTPError}
          label="Login"
          type="submit"
          variant={ButtonVariants.Primary}
        />
        <p className={typography.c3}>Don’t have a Peak account?</p>
        <Button
          label="Create Account"
          onClick={() => openModal(AuthModalTypes.SIGN_UP, { onAuthSuccess })}
          variant={ButtonVariants.Tertiary}
        />
      </div>
    </AuthModal>
  );
};
