// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OffersLayout__container___W42nF{align-items:center;background-color:var(--primary400);display:flex;flex-direction:column;margin:0 16px 16px;padding:0}@media only screen and (min-width: 390px){.OffersLayout__container___W42nF{margin:0 32px 16px;padding:16px 0}}@media only screen and (min-width: 768px){.OffersLayout__container___W42nF{align-items:start;margin:0 auto;max-width:var(--pageContentMaxWidth);padding:0 40px;width:100%}}@media only screen and (min-width: 1280px){.OffersLayout__container___W42nF{margin:32px auto 16px}}.OffersLayout__title___Tip0u{margin-top:32px}@media only screen and (min-width: 768px){.OffersLayout__title___Tip0u{margin-top:32px;text-align:left}}.OffersLayout__tabs___UfIpW{border-bottom:1px solid var(--hairline);display:flex;flex-direction:row;column-gap:16px;margin-top:0;width:100%}.OffersLayout__tab___pCaN1{color:var(--white);margin-bottom:-1px;padding:16px;text-align:center;width:100%}@media only screen and (min-width: 768px){.OffersLayout__tab___pCaN1{text-align:unset;width:unset}}.OffersLayout__tab___pCaN1.OffersLayout__isActive___HR3v8{border-bottom:1px solid var(--accent300)}", "",{"version":3,"sources":["webpack://./src/webReservations/offers/OffersLayout.scss","webpack://./src/shared/styles/breakpoints.scss"],"names":[],"mappings":"AAEA,iCACE,kBAAA,CACA,kCAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,SAAA,CCwBA,0CD9BF,iCASI,kBAAA,CACA,cAAA,CAAA,CCoBF,0CD9BF,iCAcI,iBAAA,CACA,aAAA,CACA,oCAAA,CACA,cAAA,CACA,UAAA,CAAA,CCYF,2CD9BF,iCAsBI,qBAAA,CAAA,CAIJ,6BACE,eAAA,CCGA,0CDJF,6BAII,eAAA,CACA,eAAA,CAAA,CAIJ,4BACE,uCAAA,CACA,YAAA,CACA,kBAAA,CACA,eAAA,CACA,YAAA,CACA,UAAA,CAGF,2BACE,kBAAA,CACA,kBAAA,CACA,YAAA,CACA,iBAAA,CACA,UAAA,CCnBA,0CDcF,2BAQI,gBAAA,CACA,WAAA,CAAA,CAGF,0DACE,wCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "OffersLayout__container___W42nF",
	"title": "OffersLayout__title___Tip0u",
	"tabs": "OffersLayout__tabs___UfIpW",
	"tab": "OffersLayout__tab___pCaN1",
	"isActive": "OffersLayout__isActive___HR3v8"
};
export default ___CSS_LOADER_EXPORT___;
