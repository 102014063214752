import { api } from 'webReservations/api';

export interface AuthenticatedUser {
  email: string;
  firstName: string;
  id: string;
  isRegistered: boolean;
  lastName: string;
  phone: string;
}

interface AccountInfo {
  firstName: string;
  lastName: string;
  email?: string;
  phone: string;
  phoneVerificationCode: string;
  isTosAccepted: boolean;
}

export interface CheckAuthResponse {
  user?: AuthenticatedUser;
  isAuthenticated: boolean;
}

interface Credentials {
  phone: string;
  phoneVerificationCode: string;
}

export interface DescriptionResponse {
  description: string;
}

export const login = async (
  credentials: Credentials,
): Promise<AuthenticatedUser> => api.post('/web-login', credentials);

export const signOut = async (): Promise<void> => api.post('/web-sign-out');

export const register = async (
  accountInfo: AccountInfo,
): Promise<AuthenticatedUser> => api.post('/web-register', accountInfo);

export const sendLoginVerificationCode = async (
  phoneNumber: string,
): Promise<DescriptionResponse> =>
  api.get(
    `/login/send-phone-verification-code?phone=${encodeURIComponent(
      phoneNumber,
    )}`,
  );

export const sendRegistrationVerificationCode = async (
  phoneNumber: string,
): Promise<DescriptionResponse> =>
  api.get(
    `/register/send-phone-verification-code?phone=${encodeURIComponent(
      phoneNumber,
    )}`,
  );

export const checkAuth = async (): Promise<CheckAuthResponse> =>
  api.get('/check-auth-web');
