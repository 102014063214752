import cx from 'classnames';
import { type ChangeEvent, type FormEvent, useRef, useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { ApiError } from '@shared/api/errors';
import { CONFLICT } from '@shared/statusCodes';
import { formatPhone } from '@utils/formatPhone';
import typography from '~styles/typography.scss';
import { useAuthModalContext } from '../context/AuthModalContext';
import type { AuthenticatedUser } from './apiHelpers';
import { AuthModal } from './AuthModal';
import styles from './VerifyOTPModal.scss';

const START_INDEX_OF_STRING = 0;
const CODE_LENGTH = 6;
const CODE_INDICES = Array.from(new Array(CODE_LENGTH), (_, i) => i);

export interface VerifyOTPModalProps {
  label: string;
  onClickCreateAccount?: () => void;
  onSubmit: (
    phoneVerificationCode: string,
  ) => Promise<AuthenticatedUser | ApiError | Error>;
  phone: string;
  submitButtonLabel: string;
}

export const VerifyOTPModal = ({
  label,
  onClickCreateAccount,
  onSubmit,
  phone,
  submitButtonLabel,
}: VerifyOTPModalProps) => {
  const hiddenInputRef = useRef<HTMLInputElement>(null);
  const [phoneVerificationCode, setPhoneVerificationCode] =
    useState<string>('');
  const [isInputFocused, setIsInputFocused] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [isConflict, setIsConflict] = useState(false);
  const { previousModal } = useAuthModalContext();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setError(undefined);
    const numericCode = e.target.value
      .split('')
      .filter((character) => !Number.isNaN(Number(character)))
      .slice(START_INDEX_OF_STRING, CODE_LENGTH)
      .join('');

    setPhoneVerificationCode(numericCode);
  };

  const codeIsComplete = phoneVerificationCode.length === CODE_LENGTH;

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();
    setIsLoading(true);
    void (async (): Promise<void> => {
      const response = await onSubmit(phoneVerificationCode);

      if (response instanceof ApiError) {
        if (response.statusCode === CONFLICT) {
          setIsConflict(true);
          setError(response.message);
        } else {
          setError(response.message);
        }
        setIsLoading(false);
      }
    })();
  };

  return (
    <AuthModal
      label={label}
      submitHandler={handleSubmit}
      title="Please Verify Your Phone&nbsp;Number"
    >
      <div className={styles.container}>
        <p className={cx(typography.c2, styles.info)}>
          Please input the 6-digit code we sent&nbsp;to
        </p>
        <p className={cx(typography.c2, styles.info)}>{formatPhone(phone)}</p>
        <p className={cx(typography.c3, styles.info)}>
          If you have not received a code, try going back to create an account
          or enter a different phone number
        </p>
        <input
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          className={styles.hiddenInput}
          data-testid="hidden-input"
          inputMode="numeric"
          onBlur={() => {
            setIsInputFocused(false);
          }}
          onChange={onChange}
          pattern="[0-9]*"
          ref={hiddenInputRef}
          type="text"
          value={phoneVerificationCode}
        />
        <div
          className={styles.otpContainer}
          onClick={() => {
            hiddenInputRef.current?.focus();
            setIsInputFocused(true);
          }}
          role="none"
        >
          {CODE_INDICES.map((i) => {
            const otpDigitClassNames = cx({
              [typography.c2]: true,
              [styles.otpDigit]: true,
              [styles.focused]:
                i === phoneVerificationCode.length && isInputFocused,
            });
            return (
              <div
                className={otpDigitClassNames}
                data-testid={`otpCode${i}`}
                key={i}
              >
                {phoneVerificationCode[i]}
              </div>
            );
          })}
        </div>
        {error && <p className={styles.error}>{error}</p>}
        <Button
          className={styles.button}
          isDisabled={!codeIsComplete || isLoading || isConflict}
          label={submitButtonLabel}
          type="submit"
          variant={ButtonVariants.Primary}
        />
        {onClickCreateAccount && (
          <Button
            className={cx(styles.button, styles.createAccountButton)}
            label="Create Account"
            onClick={onClickCreateAccount}
            variant={ButtonVariants.Tertiary}
          />
        )}
        <Button
          className={cx(styles.button, styles.backButton)}
          label="Back"
          onClick={previousModal}
          variant={ButtonVariants.Tertiary}
        />
      </div>
    </AuthModal>
  );
};
