import cx from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { NavLink, Outlet } from 'react-router-dom';
import { useDocumentTitle } from '@shared/hooks/setDocumentTitle';
import { MOBILE } from '@shared/styles/breakpoints';
import typography from '~styles/typography.scss';
import { useUserContext } from '../context/UserContext';
import { Unauthenticated } from '../components/Unauthenticated';
import { Layout } from '../layout/Layout';
import { OFFERS_IN_PATH, OFFERS_OUT_PATH } from '../paths';
import styles from './OffersLayout.scss';

export const OffersLayout = () => {
  const { isAuthenticated } = useUserContext();
  const isTabletOrMobile = useMediaQuery({ maxWidth: MOBILE });
  useDocumentTitle('My Offers | Peak Reservations');

  const tabClassNames = ({ isActive }: { isActive: boolean }) =>
    cx({
      [styles.tab]: true,
      [styles.isActive]: isActive,
    });

  const titleClassNames = cx({
    [styles.title]: true,
    [typography.h5]: isTabletOrMobile,
    [typography.d3]: !isTabletOrMobile,
  });

  return (
    <Layout>
      {isAuthenticated ? (
        <main className={styles.container}>
          <h1 className={titleClassNames}>Offers</h1>
          <div className={styles.tabs}>
            <NavLink className={tabClassNames} to={OFFERS_OUT_PATH}>
              Offers Out
            </NavLink>
            <NavLink className={tabClassNames} to={OFFERS_IN_PATH}>
              Offers In
            </NavLink>
          </div>
          <Outlet />
        </main>
      ) : (
        <Unauthenticated
          subtitle="Snag someone else’s reservation or sell yours."
          title="See any offers you have on reservations"
        />
      )}
    </Layout>
  );
};
