import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Button, ButtonVariants } from '@components/button/Button';
import { ControlledFormCurrencyInput } from '@components/formInputs/ControlledFormCurrencyInput';
import { isWholeNumberRule } from '@components/formInputs/rules';
import { LayoutVariant } from '@components/formInputs/sharedTypes';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { MAXIMUM_LIST_PRICE_IN_DOLLARS } from '@shared/reservations/constants';
import { formatWholeDollars, wholeDollarsToCents } from '@utils/currency';
import { RETRYABLE_ERROR_MESSAGE } from '../errors/messages';
import { updateListPrice } from './apiHelpers';
import styles from './SetListPriceModal.scss';

interface SetListPriceModalProps {
  closeModal: () => void;
  onSubmit: (reservationId: string) => void;
  reservationId: string;
  isOpen: boolean;
}

interface ListPriceFormData {
  listPrice: number;
}

export const SetListPriceModal = ({
  closeModal,
  onSubmit,
  reservationId,
  isOpen,
}: SetListPriceModalProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { control, handleSubmit } = useForm<ListPriceFormData>();
  const listPrice = useWatch({
    control,
    name: 'listPrice',
  });

  const handleSubmitListPrice = async (data: ListPriceFormData) => {
    setIsLoading(true);
    try {
      await updateListPrice(
        reservationId,
        wholeDollarsToCents(Number(data.listPrice)),
      );
      onSubmit(reservationId);
      closeModal();
    } catch {
      setErrorMessage(RETRYABLE_ERROR_MESSAGE);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      subtitle="Can’t make your reservation? Enter your price below and sell it!"
      title="Sell Reservation"
    >
      <div className={styles.container}>
        <form onSubmit={handleSubmit(handleSubmitListPrice)}>
          <ControlledFormCurrencyInput
            control={control}
            label="Sale price"
            name="listPrice"
            rules={{
              max: {
                message: `Sale price cannot exceed ${formatWholeDollars(MAXIMUM_LIST_PRICE_IN_DOLLARS)}`,
                value: MAXIMUM_LIST_PRICE_IN_DOLLARS,
              },
              min: {
                message: 'Sale price must be at least $1',
                value: 1,
              },
              required: true,
              validate: {
                isWholeNumber: isWholeNumberRule('Sale price'),
              },
            }}
            variant={LayoutVariant.Horizontal}
          />
        </form>
        <ModalActions className={styles.actions}>
          <Button
            className={styles.button}
            label="Discard"
            onClick={closeModal}
            type="button"
            variant={ButtonVariants.Tertiary}
          />
          <Button
            className={styles.button}
            isDisabled={!listPrice}
            isLoading={isLoading}
            label="Set Price"
            onClick={handleSubmit(handleSubmitListPrice)}
            type="submit"
            variant={ButtonVariants.Primary}
          />
        </ModalActions>
        <div aria-live="polite" className={styles.error}>
          {errorMessage}
        </div>
      </div>
    </Modal>
  );
};
