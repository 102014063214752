import { ApiError } from '@shared/api/errors';
import { reportAppError } from '@shared/reportAppError';
import { useAuthModalContext } from '../context/AuthModalContext';
import { useUserContext } from '../context/UserContext';
import { register } from './apiHelpers';
import { VerifyOTPModal } from './VerifyOTPModal';

export interface SignUpOTPModalProps {
  email: string;
  firstName: string;
  isTosAccepted: boolean;
  lastName: string;
  onAuthSuccess: () => void;
  phone: string;
}

export const SignUpOTPModal = ({
  email,
  firstName,
  isTosAccepted,
  lastName,
  onAuthSuccess,
  phone,
}: SignUpOTPModalProps) => {
  const { setUser, setIsAuthenticated } = useUserContext();
  const { closeModal } = useAuthModalContext();

  const onSubmit = async (phoneVerificationCode: string) => {
    try {
      const response = await register({
        email,
        firstName,
        isTosAccepted,
        lastName,
        phone,
        phoneVerificationCode,
      });

      closeModal();
      setIsAuthenticated(true);
      setUser(response);
      onAuthSuccess();
      return response;
    } catch (error) {
      reportAppError(error);
      if (error instanceof ApiError) {
        return error;
      }
      return error as Error;
    }
  };

  return (
    <VerifyOTPModal
      label="Verify Phone Number"
      onSubmit={onSubmit}
      phone={phone}
      submitButtonLabel="Register"
    />
  );
};
