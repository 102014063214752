import { useCallback, useEffect, useState } from 'react';
import { errorToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { reportAppError } from '@shared/reportAppError';
import { useUserContext } from '../context/UserContext';
import type {
  ReservationWithDetails,
  TopOffer,
} from '../reservationDetails/apiHelpers';
import { getReservation, getTopOffer } from '../reservationDetails/apiHelpers';

interface UseReservationDetails {
  fetchReservation: () => void;
  isReservationLoading: boolean;
  reservation?: ReservationWithDetails;
  topOffer: TopOffer;
}

export const useReservationDetails = (
  reservationId?: string,
): UseReservationDetails => {
  const { isAuthenticated } = useUserContext();
  const [reservation, setReservation] = useState<ReservationWithDetails>();
  const [topOffer, setTopOffer] = useState<TopOffer>({});
  const [isReservationLoading, setIsReservationLoading] = useState(true);

  const fetchReservation = useCallback(async () => {
    if (!reservationId) return;

    setIsReservationLoading(true);

    try {
      const reservationResponse = await getReservation(reservationId);

      setReservation(reservationResponse);
      if (reservationResponse.status === 'CONFIRMED') {
        const topOfferResponse = await getTopOffer(reservationId);
        setTopOffer(topOfferResponse);
      }
    } catch (e) {
      if (e instanceof ApiError) {
        errorToast({ message: e.message });
      } else {
        errorToast({
          message: 'An error occurred. Please try again.',
        });
      }
      reportAppError(e);
    } finally {
      setIsReservationLoading(false);
    }
  }, [reservationId]);

  useEffect(() => {
    if (!isAuthenticated) return;
    void fetchReservation();
  }, [isAuthenticated]);

  return { fetchReservation, isReservationLoading, reservation, topOffer };
};
