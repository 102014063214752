import cx from 'classnames';
import { useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { ApiError } from '@shared/api/errors';
import { reportAppError } from '@shared/reportAppError';
import typography from '~styles/typography.scss';
import { cancelOffer, type Offer } from './apiHelpers';
import {
  sendGa4OfferWithdrawAbortEvent,
  sendGa4OfferWithdrawConfirmEvent,
} from './gaHelpers';
import styles from './WithdrawOfferModal.scss';

export interface WithdrawOfferModalProps {
  closeModal: () => void;
  offer: Offer;
  onSubmit: () => void;
  isOpen: boolean;
}

export const WithdrawOfferModal = ({
  closeModal,
  offer,
  onSubmit,
  isOpen,
}: WithdrawOfferModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCancelCancel = () => {
    sendGa4OfferWithdrawAbortEvent({
      date: offer.date,
      offerAmount: offer.price,
      restaurantName: offer.restaurant.name,
      publicName: offer.listing.publicName,
      time: offer.time,
    });
    closeModal();
  };

  const handleCancelOffer = () => {
    setIsLoading(true);

    void (async () => {
      sendGa4OfferWithdrawConfirmEvent({
        date: offer.date,
        offerAmount: offer.price,
        publicName: offer.listing.publicName,
        restaurantName: offer.restaurant.name,
        time: offer.time,
      });
      try {
        await cancelOffer(offer.id);
        closeModal();
        onSubmit();
      } catch (e) {
        if (e instanceof ApiError) {
          setErrorMessage(e.message);
        } else {
          setErrorMessage('An unexpected error occurred. Please try again.');
        }
        setIsLoading(false);
        reportAppError(e);
      }
    })();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCancelCancel} title="Withdraw Offer">
      <div className={styles.container}>
        <p className={typography.c2}>
          This means reservation holders will no longer see your offer.
        </p>
        <p className={typography.c2}>
          If you withdraw your offer, we will release the hold on your payment
          method and you will see that reflected in your financial statements
          within 5-10 business days.
        </p>
        <ModalActions>
          <Button
            className={styles.button}
            isDisabled={isLoading}
            isLoading={isLoading}
            label="Keep Offer"
            onClick={handleCancelCancel}
            variant={ButtonVariants.Tertiary}
          />
          <Button
            className={styles.button}
            isDisabled={isLoading}
            isLoading={isLoading}
            label="Withdraw Offer"
            onClick={handleCancelOffer}
            variant={ButtonVariants.Primary}
          />
        </ModalActions>
        <p aria-live="polite" className={cx(typography.c2, styles.error)}>
          {errorMessage}
        </p>
      </div>
    </Modal>
  );
};
