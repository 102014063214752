import {
  CardElement as StripeCardElementComponent,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import type {
  CreatePaymentMethodData,
  StripeCardElement,
} from '@stripe/stripe-js';
import { type ChangeEvent, type FormEvent, useEffect, useState } from 'react';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { reportAppError } from '@shared/reportAppError';
import {
  deletePaymentMethod,
  getPaymentMethods,
  type PaymentMethod,
} from 'webReservations/checkout/apiHelpers';

export const useSettingsPaymentMethods = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(true);
  const [isPaymentMethodBeingSaved, setIsPaymentMethodBeingSaved] =
    useState(false);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [name, setName] = useState('');
  const [setupFutureCardUsage, setSetupFutureCardUsage] = useState(true);
  const [paymentMethodResponseError, setPaymentMethodResponseError] = useState<
    string | undefined
  >();

  const loadPaymentMethods = async () => {
    setIsLoading(true);
    try {
      const response = await getPaymentMethods();
      setPaymentMethods(response);
    } catch (error) {
      if (error instanceof ApiError) {
        setPaymentMethodResponseError(error.message);
      } else {
        setPaymentMethodResponseError('Failed to load payment methods');
      }
      reportAppError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    void loadPaymentMethods();
  }, []);

  const handleOnChangeName = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setPaymentMethodResponseError(undefined);
    setName(value);
  };

  const saveNewPaymentMethod = async (event: FormEvent) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return undefined;
    }

    setIsPaymentMethodBeingSaved(true);

    const stripeResponse = await stripe.createPaymentMethod({
      billing_details: {
        name,
      },
      card: elements.getElement(
        StripeCardElementComponent,
      ) as StripeCardElement,
      type: 'card',
      allow_redisplay: setupFutureCardUsage ? 'always' : 'limited',
    } as CreatePaymentMethodData);

    if (stripeResponse.error) {
      setIsPaymentMethodBeingSaved(false);
      setPaymentMethodResponseError(stripeResponse.error.message);
      return undefined;
    }

    return stripeResponse;
  };

  const handleOnError = (message: string) => {
    setIsPaymentMethodBeingSaved(false);
    setPaymentMethodResponseError(message);
  };

  const handleOnChangeSaveCard = () => {
    setSetupFutureCardUsage(!setupFutureCardUsage);
  };

  const handleOnDeletePaymentMethod = (id: string) => {
    void (async () => {
      try {
        await deletePaymentMethod(id);
        void loadPaymentMethods();
        successToast({ message: 'Payment method deleted' });
      } catch (error) {
        if (error instanceof ApiError) {
          errorToast({ message: error.message });
        } else {
          errorToast({ message: 'Failed to delete payment method' });
        }
        reportAppError(error);
      }
      void loadPaymentMethods();
    })();
  };

  return {
    handleOnChangeName,
    handleOnChangeSaveCard,
    handleOnDeletePaymentMethod,
    handleOnError,
    isLoading,
    isPaymentMethodBeingSaved,
    paymentMethodResponseError,
    paymentMethods,
    saveNewPaymentMethod,
    setupFutureCardUsage,
  };
};
