// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OfferTabs__container___EeEaB{width:100%;margin-top:1rem}@media only screen and (min-width: 768px){.OfferTabs__container___EeEaB{margin-top:2rem}}", "",{"version":3,"sources":["webpack://./src/webReservations/offers/OfferTabs.scss","webpack://./src/shared/styles/breakpoints.scss"],"names":[],"mappings":"AAEA,8BACE,UAAA,CACA,eAAA,CC4BA,0CD9BF,8BAKI,eAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "OfferTabs__container___EeEaB"
};
export default ___CSS_LOADER_EXPORT___;
