import cx from 'classnames';
import { Link } from 'react-router-dom';
import { EmailSupport } from '@shared/components/emailSupport/EmailSupport';
import {
  ABOUT_US_PATH,
  FAQ_PATH,
  HIRING_PATH,
  PRIVACY_POLICY_PATH,
  TERMS_OF_SERVICE_PATH,
} from '../paths';
import styles from './Footer.scss';

export const Footer = () => (
  <footer className={styles.footer}>
    <nav className={styles.links}>
      <Link className={styles.link} to={ABOUT_US_PATH}>
        About Peak
      </Link>
      <Link className={styles.link} to={TERMS_OF_SERVICE_PATH}>
        Terms of Service
      </Link>
      <Link className={styles.link} to={PRIVACY_POLICY_PATH}>
        Privacy Policy
      </Link>
      <EmailSupport
        className={cx(styles.link, styles.supportLink)}
        label="Support"
      />
      <Link className={styles.link} to={FAQ_PATH}>
        FAQ
      </Link>
      <Link
        className={styles.link}
        to={HIRING_PATH}
        rel="noopener noreferrer nofollow"
        target="_blank"
      >
        {`We're Hiring!`}
      </Link>
    </nav>
  </footer>
);
