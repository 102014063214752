import {
  createContext,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  useMemo,
  useState,
} from 'react';
import { useDefinedContext } from '@shared/hooks/useDefinedContext';

interface OfferCheckoutContextProviderProps {
  children: ReactNode;
}

interface OfferCheckout {
  date: string;
  guestCount: number;
  listingId: string;
  publicName: string;
  time: string;
}

export interface OfferCheckoutContextState {
  offerCheckout: OfferCheckout;
  setOfferCheckout: Dispatch<SetStateAction<OfferCheckout>>;
}

export const OfferCheckoutContext =
  createContext<OfferCheckoutContextState | null>(null);
OfferCheckoutContext.displayName = 'OfferCheckout';

export const useOfferCheckoutContext = () =>
  useDefinedContext(OfferCheckoutContext);

export const OfferCheckoutContextProvider = ({
  children,
}: OfferCheckoutContextProviderProps) => {
  const [offerCheckout, setOfferCheckout] = useState<OfferCheckout>(
    {} as OfferCheckout,
  );

  const value = useMemo<OfferCheckoutContextState>(
    () => ({
      offerCheckout,
      setOfferCheckout,
    }),
    [offerCheckout, setOfferCheckout],
  );

  return (
    <OfferCheckoutContext.Provider value={value}>
      {children}
    </OfferCheckoutContext.Provider>
  );
};
