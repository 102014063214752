import cx from 'classnames';
import { Divider } from '@components/divider/Divider';
import { useDocumentTitle } from '@shared/hooks/setDocumentTitle';
import typography from '@shared/styles/typography.scss';
import { Layout } from '../layout/Layout';
import faqCopy from './faq';
import styles from './FAQPage.scss';
import { FAQSection } from './FAQSection';

export const FAQPage = () => {
  useDocumentTitle('FAQ | Peak Reservations');

  return (
    <Layout>
      <main className={styles.main}>
        <h1 className={cx(typography.d2, styles.heading)}>
          Frequently Asked Questions
        </h1>
        <p className={cx(typography.c2, styles.subheading)}>
          Welcome to Peak Reservations. This document provides a break down of
          our key features and how to use them. We hope it helps you navigate
          and use the Peak&nbsp;Reservations site&nbsp;effectively.
        </p>
        {faqCopy.map(({ sectionName, faqs }, index) => (
          <section key={sectionName}>
            {!!index && <Divider className={styles.divider} />}
            <FAQSection sectionName={sectionName} faqs={faqs} />
          </section>
        ))}
      </main>
    </Layout>
  );
};
