import cx from 'classnames';
import { useEffect } from 'react';
import { useLoaderData, useLocation, useRevalidator } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { reportAppError } from '@shared/reportAppError';
import typography from '@shared/styles/typography.scss';
import { centsToDollar } from '@shared/utils/currency';
import type { UserAccountData } from './apiHelpers';
import { transferBalance } from './apiHelpers';
import styles from './BalancePage.scss';
import { ConnectBankAccountModal } from './ConnectBankAccountModal';
import { DisconnectBankAccountModal } from './DisconnectBankAccountModal';

const ZERO_USER_BALANCE = 0;

export const BalancePage = () => {
  const { balance, linkedAccountId } = useLoaderData() as UserAccountData;
  const { state } = useLocation();
  const revalidator = useRevalidator();
  const {
    isOpen: isConnectBankAccountModalOpen,
    open: openConnectBankAccountModal,
    close: closeConnectBankAccountModal,
  } = useIsOpen();
  const {
    isOpen: isDisconnectBankAccountModalOpen,
    open: openDisconnectBankAccountModal,
    close: closeDisconnectBankAccountModal,
  } = useIsOpen();

  const hasPlaidLinkError = !!state?.plaidLinkError;
  const hasAttemptedToConnectBankAccount =
    !!state?.attemptedToConnectBankAccount;
  const hasAttemptedToDisconnectBankAccount =
    !!state?.attemptedToDisconnectBankAccount;

  useEffect(() => {
    const displayToastMessage = () => {
      const dis = hasAttemptedToDisconnectBankAccount ? 'dis' : '';
      if (!hasPlaidLinkError) {
        successToast({
          message: `Your bank account has been successfully ${dis}connected.`,
          onClose: () => undefined,
        });
      } else {
        errorToast({
          message: `There was an error ${dis}connecting your bank account. Please try again.`,
          onClose: () => undefined,
        });
      }
    };

    if (
      hasAttemptedToConnectBankAccount ||
      hasAttemptedToDisconnectBankAccount
    ) {
      displayToastMessage();
      window.history.replaceState({}, document.title);
    }
  }, [state]);

  const handleOnClickTransferToBank = async () => {
    try {
      await transferBalance();
      revalidator.revalidate();
      successToast({
        message:
          'Your transfer has been initiated. Please allow for bank processing times.',
        onClose: () => undefined,
      });
    } catch (error) {
      reportAppError(error);
      errorToast({
        message:
          'There was an error initiating your transfer. Please try again.',
        onClose: () => undefined,
      });
    }
  };

  return (
    <div className={styles.balanceContainer}>
      <h2 className={cx(typography.h4, styles.header)}>Peak Balance</h2>
      <h3 className={cx(typography.h1, styles.balance)}>
        {centsToDollar(balance)}
      </h3>
      <p className={cx(typography.t2, styles.text)}>
        You can transfer your money any time.
      </p>
      {linkedAccountId ? (
        <div className={styles.actionContainer}>
          <Button
            isDisabled={balance === ZERO_USER_BALANCE}
            label="Transfer to Bank"
            onClick={handleOnClickTransferToBank}
            variant={ButtonVariants.Primary}
          />
          <Button
            className={styles.disconnectBankAccountButton}
            label="Disconnect Bank Account"
            onClick={openDisconnectBankAccountModal}
            variant={ButtonVariants.Primary}
          />
        </div>
      ) : (
        <Button
          label="Connect Bank Account"
          onClick={openConnectBankAccountModal}
          variant={ButtonVariants.Primary}
        />
      )}
      <ConnectBankAccountModal
        closeModal={closeConnectBankAccountModal}
        isOpen={isConnectBankAccountModalOpen}
      />
      <DisconnectBankAccountModal
        closeModal={closeDisconnectBankAccountModal}
        isOpen={isDisconnectBankAccountModalOpen}
      />
    </div>
  );
};
