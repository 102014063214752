import cx from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ApiError } from '@shared/api/errors';
import { reportAppError } from '@shared/reportAppError';
import { CENTS_IN_DOLLAR } from '@utils/currency';
import typography from '~styles/typography.scss';
import { useTimer } from '../hooks/useTimer';
import { RESERVATIONS_INACTIVE_PATH } from '../paths';
import type {
  ReservationWithDetails,
  TopOffer,
} from '../reservationDetails/apiHelpers';
import type { Reservation } from '../reservations/apiHelpers';
import { formatTimeRemaining } from '../utils/formatTimeRemaining';
import styles from './AcceptTopOfferModal.scss';
import { acceptOffer } from './apiHelpers';
import {
  sendGa4OfferAcceptAbortEvent,
  sendGa4OfferAcceptConfirmEvent,
} from './gaHelpers';

const NO_TIME_REMAINING = 0;

export interface AcceptTopOfferModalProps {
  closeModal: () => void;
  isOpen: boolean;
  offer: TopOffer;
  reservation: Reservation | ReservationWithDetails;
}

export const AcceptTopOfferModal = ({
  closeModal,
  isOpen,
  offer,
  reservation,
}: AcceptTopOfferModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const timeRemaining = useTimer(offer);
  const navigate = useNavigate();

  const handleAcceptOffer = (): void => {
    setIsLoading(true);

    sendGa4OfferAcceptConfirmEvent({
      date: reservation.date,
      offerAmount: offer.price,
      publicName: reservation.listing.publicName,
      restaurantName: reservation.restaurant.name,
      time: reservation.time,
    });

    void (async () => {
      try {
        await acceptOffer(offer.id, reservation.id);
        closeModal();
        navigate(RESERVATIONS_INACTIVE_PATH);
      } catch (e) {
        if (e instanceof ApiError) {
          setErrorMessage(e.message);
        } else {
          setErrorMessage('An error occurred. Please try again.');
        }
        reportAppError(e);
        setIsLoading(false);
      }
    })();
  };

  const handleCloseModal = () => {
    sendGa4OfferAcceptAbortEvent({
      date: reservation.date,
      offerAmount: offer.price,
      restaurantName: reservation.restaurant.name,
      publicName: reservation.listing.publicName,
      time: reservation.time,
    });

    closeModal();
  };

  const offerHasExpired =
    (timeRemaining || NO_TIME_REMAINING) <= NO_TIME_REMAINING;

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal} title="Top Offer">
      <div className={styles.container}>
        <p className={cx(typography.h1, styles.price)}>{`$${
          offer.price / CENTS_IN_DOLLAR
        }`}</p>
        <div className={cx(typography.t2, styles.expire)}>
          {offerHasExpired
            ? formatTimeRemaining(timeRemaining)
            : `Offer Expires in ${formatTimeRemaining(timeRemaining)}`}
        </div>
        <Button
          isDisabled={offerHasExpired}
          isLoading={isLoading}
          label="Accept Offer"
          onClick={handleAcceptOffer}
          variant={ButtonVariants.Primary}
        />
        <div className={styles.error}>{errorMessage}</div>
      </div>
    </Modal>
  );
};
